import React, { Fragment, memo, useEffect, useState } from "react";
import ImgComponent from "../../Image/ImgComponent";
import { useSelector } from "react-redux";
import { getSasToken } from "../../../../stores";
import CkEmptyImage from "../../../../CkUI/components/molecules/CkEmptyImage";

interface ICount {
  count: number;
}

interface IProps {
  images: any[];
  updateWorkshopData: Function;
  blobListDeleted: any[];
  setBlobListDeleted: Function;
  reorganizeImages: Function;
  deleteImage: Function;
  beforeUploadProp: Function;
  onChangeProp: Function;
  uploadImage: Function;
  imageList: any[];
  setImageList: Function;
}

const LogoSelection: React.FC<IProps> = ({
  images,
  updateWorkshopData,
  blobListDeleted,
  setBlobListDeleted,
  reorganizeImages,
  deleteImage,
  beforeUploadProp,
  onChangeProp,
  uploadImage,
  imageList,
  setImageList,
}) => {
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [logoIndex, setLogoIndex] = useState<number>(0);

  const sasToken: string = useSelector(getSasToken);

  useEffect(() => {
    setImageList(images);
  }, []);

  useEffect(() => {
    if (imageList && Array.isArray(imageList) && imageList.length > 0) {
      // Force logo to be first always
      const imageLogoIndex = imageList.findIndex(
        (image) => image.description === "LOGO"
      );
      if (imageList[0].description !== "LOGO" && imageLogoIndex !== -1) {
        const imageCopy = JSON.parse(JSON.stringify(imageList[imageLogoIndex]));
        const imageListCopy = JSON.parse(JSON.stringify(imageList));
        imageListCopy.splice(imageLogoIndex, 1);
        imageListCopy.unshift(imageCopy);
        setImageList(imageListCopy);
        setLogoUrl(imageListCopy[0].photoUrl);
        setLogoIndex(0);
      } else if (imageLogoIndex === 0) {
        setLogoUrl(imageList[0].photoUrl);
        setLogoIndex(0);
      }
      updateWorkshopData({ workshopPhotos: imageList });
    }
  }, [imageList]);

  const uploadPropsLogo = {
    name: "file",
    multiple: false,
    beforeUpload: (file: any) => beforeUploadProp(file, "LOGO"),
    customRequest: ({ onSuccess, onError, file }: any) => {
      uploadImage(onSuccess, onError, file, "LOGO");
    },
    onChange(info: any) {
      onChangeProp(info);
    },
  };

  return (
    <Fragment>
      <div className="logo-container">
        {!!logoUrl && sasToken ? (
          <ImgComponent
            photoUrl={logoUrl}
            imgIndex={logoIndex}
            reorganizeImages={reorganizeImages}
            imageList={imageList}
            deleteImage={deleteImage}
          />
        ) : (
          <CkEmptyImage {...uploadPropsLogo} />
        )}
      </div>
    </Fragment>
  );
};

export default memo(LogoSelection);
