import React, { memo } from "react";
import { Col, Row, Form, Input } from "antd";
import "./styles.css";
import { CkIcon } from "../../../../CkUI";

interface IProps {
  updateWorkshopData?: Function;
}

const FiscalData: React.FC<IProps> = ({ updateWorkshopData }) => {
  return (
    <Row justify="start" className="fiscal-data-form-items">
      <Col span={24}>
        <p className="form-section-title">
          Datos fiscales
        </p>
      </Col>
      <Col sm={24} md={8} style={{ width: "100%", paddingRight: "20px" }}>
        <Form.Item
          className="social-reason-form-item"
          rules={[
            {
              required: true,
              message: "Ingresa el RFC",
            },
          ]}
          name="workshopRFC"
          extra="*RFC"
        >
          {updateWorkshopData ? (
            <Input
              placeholder="Escribe el RFC"
              onChange={(e) => {
                updateWorkshopData({ WorkshopRFC: e.target.value })
              }}
            />
          ) : (
            <Input
              placeholder="Escribe el RFC"
            />
          )}
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CkIcon name="information-round" width={18} height={18}/>
          <p style={{ fontSize: "12px", color: "#666666", fontWeight: 400, margin: 0, paddingLeft: "5px" }}>
            Tu RFC nos permitirá ofrecerte funcionalidades complementarias.
          </p>
        </div>
      </Col>

      <Col sm={24} md={8} style={{ width: "100%", paddingRight: "20px" }}>
        <Form.Item
          className="social-reason-form-item"
          name="workshopSocialReason"
          extra="Razón social"
        >
          {
            updateWorkshopData ? (
              <Input
                placeholder="Escribe la razón social"
                onChange={(e: any) => {
                  updateWorkshopData({ WorkshopSocialReason: e.target.value })
                }}
              />
            ) : (
              <Input
                placeholder="Escribe la razón social"
              />
            )
          }
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CkIcon name="information-round" width={18} height={18}/>
          <p style={{ fontSize: "12px", color: "#666666", fontWeight: 400, margin: 0, paddingLeft: "5px" }}>
            Tu razón social nos permitirá ofrecerte funcionalidades complementarias.
          </p>
        </div>

      </Col>

    </Row>
  );
};

export default memo(FiscalData);
