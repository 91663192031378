import React, { Fragment, memo, useEffect, useState } from "react";
import { Form, Checkbox, Select } from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import "./styles.css";
import { VehicleBrand } from "shared";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
const { Option } = Select;
interface IProps {
  updateWorkshopData: Function;
  workshopData: any;
}

const CharacteristicSelection: React.FC<IProps> = ({ updateWorkshopData, workshopData }) => {
  const [brandSelectionEnabled, setBrandSelectionEnabled] = useState(false);
  const [availableVehicleBrands, setAvailableVehicleBrands] = useState<VehicleBrand[]>([]);

  const optionList: any[] = [
    { value: "INFO0001", label: "Recepción de vehículos 24/7" },
    { value: "INFO0002", label: "Recolección de vehículo a domicilio" },
    { value: "INFO0003", label: "Gestión de servicio de grúa" },
    { value: "INFO0004", label: "Especialización por marca (máximo 3)" },
  ];

  useEffect(() => {
    setBrandSelectionEnabled(workshopData && workshopData.additionalInfo.includes("INFO0004"))
  }, [workshopData]);

  useEffect(() => {
    const fetchData = async () => {
      const vehicleBrandsRequests = await CatalogAPI.fetchVehicleBrands(
        "AUTOS"
      );
      setAvailableVehicleBrands(vehicleBrandsRequests.data || []);
    };
    fetchData();
  }, []);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    updateWorkshopData({ additionalInfo: checkedValues });
  };

  const onChangeSelect = (value) => {
    updateWorkshopData({ workshopBrands: value })
  }

  return (
    <Fragment>
      <p className="form-section-title">
        Características adicionales
      </p>
      <Form.Item>
        <div className="additional-form-container">
          <Checkbox.Group
            className="workshop-form-checkbox-group --custom --orange"
            options={optionList}
            onChange={onChange}
            defaultValue={workshopData.additionalInfo}
          />
        </div>

      </Form.Item>
      <Form.Item name="workshopBrands">
        <Select
          disabled={!brandSelectionEnabled}
          showSearch
          mode="multiple"
          style={{ maxWidth: "420px"}}
          value={workshopData?.workshopBrands || []}
          onChange={(value) => onChangeSelect(value)}
          placeholder="Selecciona las marcas"
          getPopupContainer={(triggerNode: any) => triggerNode || document.body}
        >
          {availableVehicleBrands &&
            availableVehicleBrands.map((item) => (
              <Option
                disabled={
                  workshopData?.workshopBrands &&
                  workshopData?.workshopBrands?.length === 3 &&
                  workshopData?.workshopBrands.every(
                    (selected) => selected !== item.code
                  )
                }
                dropdownStyle={{ textTransform: "capitalize" }}
                value={item.code}
                key={item.code}
              >
                {item.desc}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Fragment>
  );
};

export default memo(CharacteristicSelection);
