import React, { FC, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ConfigurationsAPI } from "../../Services/axios";

interface IProps {
  styles: object;
  setRecaptchaValidation: Function
}
const ReCaptcha : FC<IProps> = ({
  styles,
  setRecaptchaValidation
}) => {
  const [showReCAPTCHA, setShowReCAPTCHA] = useState<undefined | boolean>();
  const key = process.env.REACT_APP_GOOGLE_RECAPTCHA || "";

  const init = async () => {
    const showRequest = await ConfigurationsAPI.showReCaptcha("Workshop").then(response => response.data).catch(() => false);
    setShowReCAPTCHA(showRequest === true);
  }

  useEffect(() => {
    showReCAPTCHA === false && setRecaptchaValidation(true);
  }, [showReCAPTCHA]);

  useEffect(() => {
    init();
  }, []);

  if (!!key === false || showReCAPTCHA === undefined || showReCAPTCHA === false) return null;

  return (
    <ReCAPTCHA
      style={styles}
      sitekey={key}
      onChange={(e) => e && setRecaptchaValidation(true)}
      onExpired={() => setRecaptchaValidation(false)}
    />
  );
};

export default ReCaptcha;
