import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serviceSAS } from "shared";
import { getSasToken } from "../../../stores";

const SasTokenRefresher = () => {
  const intervalID = useRef<NodeJS.Timer | null>(null);
  const dispatch = useDispatch();
  const sasToken = useSelector(getSasToken);

  const getSasTokenAzure = async () => {
    dispatch(serviceSAS());
  };

  useEffect(() => {
    intervalID.current = setInterval(getSasTokenAzure, 240000);
    return () => {
      intervalID.current && clearInterval(intervalID.current);
      intervalID.current = null;
    };
  }, []);

  useEffect(() => {
    if (!!sasToken === false) getSasTokenAzure();
  }, [sasToken]);

  return <></>
}

export default SasTokenRefresher;
