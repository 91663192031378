import React, {
  useCallback,
  Fragment,
  memo,
  useState,
  useEffect,
  useRef,
} from "react";
import { Form, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getSasToken,
  getUser,
  getWorkshopFormData,
  getWorkshopFormLocation,
  setData,
} from "../../../stores";
import ActionButtons from "./ActionButtons";
import CharacteristicSelection from "./CharacteristicSelection";

import ContactData from "./ContactData";
import WorkshopData from "./WorkshopData";
import ServicesSelection from "./ServicesSelection";
import LocationSelection from "./LocationSelection";
import WorkshopGallery from "./WorkshopGallery";
import ScheduleSelection from "./ScheduleSelection";
import { PackagesAPI, WarrantyAPI, WorkshopAPI } from "../../Services/axios";
import { BlobServiceClient } from "@azure/storage-blob";
import { addWorkshop, Workshop, WorkshopImage } from "shared";
import Compressor from "compressorjs";
import { IPackage } from "./PackagesSelectionModal";
import { useHistory } from "react-router-dom";
import { CkMessage } from "../../../CkUI";
import { gtmAddLayer, useIsMobileScreen } from "../../Utilities";
import FiscalData from "./FiscalData";

import "./styles.css";
import { WorkshopWarranty } from "../Workshop/WorkshopWarranty";
import { formatWarrantyData } from "../../../Screens/AccountSetup/warrantyFunctions";

const account = process.env.REACT_APP_BLOB_ACCOUNT;
const containerName = process.env.REACT_APP_IMAGES_CONTAINER as string;
let blobService: any = null;
let containerClient: any = null;

interface IBlobImages {
  name: string;
}
interface IProps {
  workshopData: any;
  isNewAccount?: boolean;
}

interface ICount {
  count: number;
}

const WorkshopForm: React.FC<IProps> = ({ workshopData, isNewAccount }) => {
  const isMobile = useIsMobileScreen(992);
  const loadingMessageKey = useRef<string>("");
  const [form] = Form.useForm();
  const user = useSelector(getUser);
  const workshopFormLocation = useSelector(getWorkshopFormLocation);
  const dispatch = useDispatch();
  const history = useHistory();
  const sasToken: string = useSelector(getSasToken);
  const [blobListDeleted, setBlobListDeleted] = useState<Array<IBlobImages>>(
    []
  );
  const [resetSchedule, setResetSchedule] = useState<boolean>(false);
  const [imageList, setImageList] = useState<Array<WorkshopImage>>([]);
  const [allPackages, setAllPackages] = useState([]);
  const [isNewWorkshop, setIsNewWorkshop] = useState<boolean>(false);

  useEffect(() => {
    setIsNewWorkshop(!!workshopData.id === false);
  }, [workshopData]);

  useEffect(() => {
    const values = form.getFieldsValue();
    if (workshopFormLocation?.latitude) {
      form.setFieldsValue({
        location: workshopFormLocation,
      });
    }
  }, [workshopFormLocation]);
  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      containerClient = blobService.getContainerClient(containerName);
    }
  }, [sasToken]);

  useEffect(() => {
    form.setFieldsValue({
      warranty: {
        packagesInfo: [],
        servicesInfo: [],
      },
    });
  }, []);

  useEffect(() => {
    const getPackages = async () => {
      const result = await PackagesAPI.getPackagesDetail();
      const packages = result.data.filter(
        (pack: IPackage) => pack.servicePackage.isActive
      );
      if (workshopData?.id) {
        // If the workshop is an existing one, then
        // the workshops packages should be updated with the existing comments and price
        // Get the Workshop Packages
        // const workshopPackages = await PackagesAPI.getWorkshopPackages(workshopData?.id!);
        // This is the full workshop plus the data saved from the workshop (comment and price)
        // const fullWorkshops = packages.map((pack) => {
        //    const matchWorkshop = workshopPackages.data.find(
        //      (a: any) => a.servicePackageSetId === pack.servicePackageSetId
        //    )
        //    if(matchWorkshop) {
        //        return { ...pack, price: matchWorkshop.price, comment: matchWorkshop.comment ? matchWorkshop.comment : "",}
        //    } else {
        //        return { ...pack, price: 0, comment: "" };
        //    }
        // });
      }
      setAllPackages(packages);
    };
    getPackages();
  }, []);

  const handleFormChange = useCallback((changedValues: any, allValues: any) => {
    if (changedValues) {
      sessionStorage.setItem("talleres_formchanged", "true"); // setIsTouched
    }
    //console.log("handleFormChange: ", allValues);
  }, []);

  const updateWorkshopData = (workshopUpdate: any) => {
    dispatch(setData({ ...workshopData, ...workshopUpdate }));
  };

  const validateMinimumServices = (data) => {
    let isValid = true;
    /* 
    const isPackageNotEmpty = allPackages?.some((res: any) => res.price > 0);
    const isServicesNotEmpty = data?.serviceTypes.length > 0;

    isValid = isServicesNotEmpty || isPackageNotEmpty;

    if (!isValid) {
      CkMessage({
        type: "warning",
        text: "Selecciona al menos un paquete o servicio.",
      });
    } 
    */

    return isValid;
  };

  const validateSchedule = (data: any) => {
    let isValid = true;
    const wh = data.workingHours;
    if (data.UseSameTimeEveryDay === true) {
      if (data.mondayToFridayDayHours) {
        if (
          data.everyDayHours === undefined ||
          data.everyDayHours.endingTime === undefined ||
          data.everyDayHours.startingTime === undefined
        ) {
          isValid = false;
          CkMessage({
            type: "warning",
            text: "Ingresa horario de trabajo para todos los dias",
          });
        }
      }
    } else if (data.UseSameTimeMondayToFriday === true) {
      if (
        data.mondayToFridayDayHours.endingTime === undefined ||
        data.mondayToFridayDayHours.startingTime === undefined
      ) {
        isValid = false;
        CkMessage({
          type: "warning",
          text: "Ingresa horario de trabajo de lunes a viernes",
        });
      }
    } else {
      if (wh) {
        let h = "";
        wh.map((res: any) => {
          if (res.openTime.length > 0) {
            h = res.openTime.length;
          }
        });
        if (h.length == 0) {
          isValid = false;
          CkMessage({ type: "warning", text: "Ingresa horario de trabajo" });
        }
      } else {
        isValid = false;
        CkMessage({ type: "warning", text: "Ingresa horario de trabajo" });
      }
    }
    return isValid;
  };

  const validateCoordinates = async (data) => {
    let isValid = true;
    let coords = data?.location?.latitude + "," + data?.location?.longitude;
    const coordinatesAllowedRes = await WorkshopAPI.areCoordinatesAllowed(
      data?.id?.length > 0 ? data?.id : 0,
      coords
    );
    isValid = coordinatesAllowedRes?.data;

    if (!isValid) {
      CkMessage({
        type: "warning",
        text: "Ya existe otro taller en la ubicación seleccionada",
      });
    }
    return isValid;
  };

  const validateDuplicates = async (data) => {
    const nameUpperCase = data?.name.toUpperCase();
    const res = await WorkshopAPI.searchDuplicates(
      undefined,
      nameUpperCase,
      undefined
    );

    if (res?.data && res?.data?.length > 0) {
      CkMessage({
        type: "warning",
        text: "Ya existe un taller con este nombre en otra cuenta",
      });
      return false;
    } else {
      return true;
    }
  };

  const validateWSIdentificator = async (data: any) => {
    let isValid = true;
    const res = await WorkshopAPI.validateWorkshopIdentifier(
      data.WorkshopIdentificator,
      user?.email
    );
    return isValid;
  };

  const validateComplexFields = async (data: any) => {
    const validSchedule = await validateSchedule(data);
    const hasMinServices = await validateMinimumServices(data);
    const validCoordinates = await validateCoordinates(data);
    const isUniqueName = await validateDuplicates(data);
    const isUniqueWSIdentificator = await validateWSIdentificator(data);
    return validSchedule && hasMinServices && validCoordinates && isUniqueName;
  };

  const saveWarranty = (workshopId: string, payload: any) => {
    WarrantyAPI.updateWarranty(workshopId, payload);
  };

  const onSubmit = async (values: any) => {
    const allData = {
      ...workshopData,
      location: workshopFormLocation,
      everyDayHours: values.everyDayHours,
      mondayToFridayDayHours: values.mondayToFridayDayHours,
    };

    ////Format data////

    //Format Schedule
    let workingHoursTemp = [];
    if (
      workshopData.UseSameTimeEveryDay &&
      values.everyDayHours.startingTime &&
      values.everyDayHours.endingTime
    ) {
      let openTime = values.everyDayHours.startingTime.toDate().toISOString();
      let closeTime = values.everyDayHours.endingTime.toDate().toISOString();
      openTime = openTime.substring(openTime.indexOf("T") + 1);
      closeTime = closeTime.substring(closeTime.indexOf("T") + 1);
      const dayCodes = [1, 2, 3, 4, 5, 6, 7];
      dayCodes.forEach((dayCode) => {
        workingHoursTemp.push({
          dayCode: dayCode,
          openTime: openTime,
          closeTime: closeTime,
          isOpen: true,
        });
      });
    } else if (
      workshopData.UseSameHourMondayToFriday &&
      values.mondayToFridayDayHours.startingTime &&
      values.mondayToFridayDayHours.endingTime
    ) {
      let openTime = values.mondayToFridayDayHours.startingTime
        .toDate()
        .toISOString();
      let closeTime = values.mondayToFridayDayHours.endingTime
        .toDate()
        .toISOString();

      openTime = openTime.substring(openTime.indexOf("T") + 1);
      closeTime = closeTime.substring(closeTime.indexOf("T") + 1);
      const dayCodes = [1, 2, 3, 4, 5];
      dayCodes.forEach((dayCode) => {
        workingHoursTemp.push({
          dayCode: dayCode,
          openTime: openTime,
          closeTime: closeTime,
          isOpen: true,
        });
      });
      //For Saturday and Sunday
      workingHoursTemp.push({
        dayCode: workshopData.workingHours[5].dayCode,
        openTime: workshopData.workingHours[5].openTime,
        closeTime: workshopData.workingHours[5].closeTime,
        isOpen: workshopData.workingHours[5].isOpen,
      });
      workingHoursTemp.push({
        dayCode: workshopData.workingHours[6].dayCode,
        openTime: workshopData.workingHours[6].openTime,
        closeTime: workshopData.workingHours[6].closeTime,
        isOpen: workshopData.workingHours[6].isOpen,
      });
    } else {
      workingHoursTemp = workshopData.workingHours;
    }

    allData.workingHours = workingHoursTemp;

    // prepare car types
    let newCarTypes =
      values.carTypes?.map((item: any) => ({
        code: item.toUpperCase(),
        description: item,
      })) || [];

    allData.carTypes;

    let formattedAdditionalInfo = allData.additionalInfo.map((info) => ({
      code: info.toUpperCase(),
      description: info,
    }));

    // Order photos
    const photosOrdered = workshopData.workshopPhotos.map(
      (photo: any, index: number) => ({ ...photo, order: index })
    );

    //TODO: Check warranties
    /* 
    const formatedWarrantyData = formatWarrantyData(values);
    if (
      formatedWarrantyData.warrantyServices === "errTimeRange" ||
      formatedWarrantyData.warrantyPackages === "errTimeRange"
    ) {
      CkMessage({
        type: "warning",
        text: "El rango de tiempo de las garantías no puede estar vacío",
      });
      return;
    } 
    */

    const isDataValid = await validateComplexFields(allData);

    if (isDataValid) {
      let workshopPayload: Workshop = {
        id: allData.id === "" ? 0 : allData.id,
        name: allData?.name?.toUpperCase(),
        typeCodes: allData.typeCodes,
        email: allData.email,
        mySAPId: allData.mySAPId,
        openSince: allData.openSince ?? "-1",
        workshopPhoneNumber: allData.workshopPhoneNumber?.split("-").join(""),
        customerPhoneNumber: allData.customerPhoneNumber?.split("-").join(""),
        workshopWebsite: allData.workshopWebsite,
        location: {
          zipCode: workshopFormLocation?.zipCode,
          city: workshopFormLocation?.city,
          municipality: workshopFormLocation?.municipality,
          neighborhood: workshopFormLocation?.neighborhood,
          address: workshopFormLocation?.address,
          latitude:
            workshopFormLocation?.latitude === "0" ||
            workshopFormLocation?.latitude === 0
              ? ""
              : workshopFormLocation?.latitude,
          longitude:
            workshopFormLocation?.longitude === "0" ||
            workshopFormLocation?.latitude === 0
              ? ""
              : workshopFormLocation?.longitude,
          WorkshopStateProvince: workshopFormLocation?.WorkshopStateProvince,
        },
        workshopPhotos: photosOrdered,
        workingHours: workingHoursTemp,
        workshopSocialMedia: allData.workshopSocialMedia,
        allowWhatsapp: false,
        carTypes: newCarTypes,
        additionalInfo: formattedAdditionalInfo,
        workshopBrands: allData.workshopBrands || [],
        workshopCategoryId: allData.workshopCategoryId,
        about: allData.about || "",
        WorkshopIdentificator: allData?.WorkshopIdentificator,
        WorkshopRFC: allData?.WorkshopRFC,
        WorkshopSocialReason: allData?.WorkshopSocialReason,
        packages: allPackages.filter((p) => p.price > 0),
        serviceTypes: allData.serviceTypes,
        /* 
        workshopServiceWarranty: values.warranty.enable,
        workshopWarrantyFileUrl: values.warranty.conditions.includes("https")
          ? values.warranty.conditions
          : "", 
          */
      };

      blobListDeleted.forEach((res) => {
        containerClient.deleteBlob(res.name, sasToken);
      });

      dispatch(addWorkshop(workshopPayload)).then(async (result: any) => {
        gtmAddLayer({
          workshop_category: result.payload.typeCode,
          workshop_ID: result.payload.id,
          event: "add_workshop",
        });

        if (result.payload) {
          const packages = workshopPayload.packages.map((pack: any) => {
            return {
              ServicePackagesetId: pack.servicePackageSetId,
              Price: pack.price,
              Comment: pack.comment,
            };
          });
          if (packages.length > 0) {
            const resWs = await PackagesAPI.postWorkshopPackages(
              result.payload.id,
              packages
            );
          }

          // saveWarranty(result.payload.id, formatedWarrantyData);

          setResetSchedule(true);
          if (isNewAccount) history.push("/");
          else history.push("/mis-talleres"); //Go to my workshops page
        }
      });

      sessionStorage.removeItem("imagesWorkshop");
    }
  };

  const reorganizeImages = (oldIndex: number, newIndex: number) => {
    const imagesCopy = [...imageList];
    const oldImageCopy = JSON.parse(JSON.stringify(imagesCopy[oldIndex]));
    imagesCopy.splice(oldIndex, 1);
    imagesCopy.splice(newIndex, 0, oldImageCopy);
    setImageList(imagesCopy);
    updateWorkshopData({ workshopPhotos: imagesCopy });
  };

  const deleteImage = async (index: number) => {
    let updatedImageList = [...imageList];
    try {
      let temp = [...blobListDeleted];
      await WorkshopAPI.photosCount(imageList[index].photoUrl!)
        .then((response: any) => {
          let data: ICount = response.data;
          if (data.count === 1) {
            temp.push({
              name: imageList[index].name!,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        });

      setBlobListDeleted(temp);
      updatedImageList.splice(index, 1);

      setImageList(updatedImageList);
      CkMessage({
        type: "success",
        text: "Imagen eliminada exitosamente. Recuerda guardar para salvar los cambios a las imágenes.",
      });
    } catch (error) {
      if (error.statusCode && error.statusCode === 404) {
        updatedImageList.splice(index, 1);
        setImageList(updatedImageList);
        return;
      }
      CkMessage({
        type: "error",
        text: "Ha ocurrido un error al eliminar la imagen.",
      });
    }
  };

  const beforeUploadProp = (file: any, fileType: "LOGO" | "GALLERY") => {
    let imgType: Object = {
      "image/png": true,
      "image/jpg": true,
      "image/jpeg": true,
    };
    if (!imgType.hasOwnProperty(file.type)) {
      CkMessage({
        type: "error",
        text: `${file.name} no es un formato soportado de imágen.`,
      });
    }
    const isLt2M = file.size > 3120968 ? true : false;
    if (isLt2M) {
      file.flag = true;
      CkMessage({ type: "error", text: `${file.name} supera los 3 MB.` });
      return false;
    }
    if (
      fileType === "GALLERY" &&
      imageList.filter((file) => file.description !== "LOGO").length >= 8
    ) {
      CkMessage({
        type: "error",
        text: "Solo se pueden agregar hasta 8 imágenes y un logo.",
      });
      return false;
    }
    return imgType.hasOwnProperty(file.type);
  };

  const onChangeProp = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      loadingMessageKey.current = CkMessage({
        type: "loading",
        text: `Subiendo imagen ${info.file.name}`,
        ...(loadingMessageKey.current
          ? { messageToDestroy: loadingMessageKey.current }
          : {}),
      });
    } else if (status === "done") {
      CkMessage({
        type: "success",
        text: `${info.file.name} subida exitosamente. Recuerda guardar para salvar los cambios a las imágenes.`,
        ...(loadingMessageKey.current
          ? { messageToDestroy: loadingMessageKey.current }
          : {}),
      });
      if (loadingMessageKey.current) loadingMessageKey.current = "";
    } else if (status === "error") {
      CkMessage({
        type: "error",
        text: `${info.file.name} error al subir imagen.`,
        ...(loadingMessageKey.current
          ? { messageToDestroy: loadingMessageKey.current }
          : {}),
      });
      if (loadingMessageKey.current) loadingMessageKey.current = "";
    }
  };

  const uploadImage = async (
    onSuccess: Function,
    onError: Function,
    file: any,
    description: string
  ) => {
    let date = new Date();
    let fileName = `${date.getTime()}_${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    try {
      let newArray = [...imageList];
      if (description === "LOGO") {
        newArray = newArray.filter((file, index) => {
          if (file.description === "LOGO") {
            deleteImage(index);
          } else {
            return file;
          }
        });
      }
      new Compressor(file, {
        quality: 0.6,
        convertTypes:
          "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
        convertSize: 2000000,
        async success(result) {
          await blockBlobClient.upload(result, result.size);
          newArray = [
            ...(description === "LOGO"
              ? [
                  {
                    name: fileName,
                    photoUrl: blockBlobClient.url.split("?")[0],
                    description,
                  },
                ]
              : []),
            ...newArray,
            ...(description !== "LOGO"
              ? [
                  {
                    name: fileName,
                    photoUrl: blockBlobClient.url.split("?")[0],
                    description,
                  },
                ]
              : []),
          ];
          setImageList(newArray);
          sessionStorage.setItem("imagesWorkshop", JSON.stringify(newArray));
          onSuccess();
        },
      });
    } catch (error) {
      onError();
    }
  };

  return (
    <Fragment>
      <Form
        form={form}
        name="workshop_form"
        className="workshop-form"
        initialValues={workshopData}
        scrollToFirstError
        onValuesChange={handleFormChange}
        onFinish={onSubmit}
      >
        <WorkshopData
          workshopData={workshopData}
          updateWorkshopData={updateWorkshopData}
          blobListDeleted={blobListDeleted}
          setBlobListDeleted={setBlobListDeleted}
          reorganizeImages={reorganizeImages}
          deleteImage={deleteImage}
          beforeUploadProp={beforeUploadProp}
          onChangeProp={onChangeProp}
          uploadImage={uploadImage}
          imageList={imageList}
          setImageList={setImageList}
        />
        <Divider />

        {/* 
        <ServicesSelection
          updateWorkshopData={updateWorkshopData}
          workshopData={workshopData}
          allPackages={allPackages}
          setAllPackages={setAllPackages}
        /> 
        <Divider />
        */}

        {/* 
        <div style={{ display: isMobile ? "none" : "" }}>
          <WorkshopWarranty
            form={form}
            packageList={allPackages}
            serviceList={workshopData.serviceTypes}
          />
          <Divider />
        </div> 
          */}

        <WorkshopGallery
          reorganizeImages={reorganizeImages}
          workshopData={workshopData}
          updateWorkshopData={updateWorkshopData}
          blobListDeleted={blobListDeleted}
          setBlobListDeleted={setBlobListDeleted}
          beforeUploadProp={beforeUploadProp}
          uploadImage={uploadImage}
          onChangeProp={onChangeProp}
          deleteImage={deleteImage}
          imageList={imageList}
          setImageList={setImageList}
        />
        <Divider />

        <ContactData updateWorkshopData={updateWorkshopData} />
        <Divider style={{ margin: "0 0 24px 0" }} />

        <FiscalData updateWorkshopData={updateWorkshopData} />
        <Divider style={{ margin: "0 0 24px 0" }} />

        <LocationSelection
          updateWorkshopData={updateWorkshopData}
          location={workshopData.location}
        />
        <Divider />

        <ScheduleSelection
          updateWorkshopData={updateWorkshopData}
          workshopData={workshopData}
          reset={resetSchedule}
          setReset={setResetSchedule}
        />
        <Divider />

        <CharacteristicSelection
          updateWorkshopData={updateWorkshopData}
          workshopData={workshopData}
        />
        <Divider />

        <ActionButtons
          isNewWorkshop={isNewWorkshop}
          redirectTo={isNewAccount ? "/configura-tu-taller" : "mis-talleres"}
        />
      </Form>
    </Fragment>
  );
};

export default memo(WorkshopForm);
