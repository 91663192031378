import React, { Fragment, memo } from "react";
import ImgComponent from "../../Image/ImgComponent";
import { Row } from "antd";
import { WorkshopImage } from "shared";
import { useIsMobileScreen } from "../../../Utilities";
import "./styles.css";
import CkEmptyImage from "../../../../CkUI/components/molecules/CkEmptyImage";

interface IProps {
  workshopData: any;
  updateWorkshopData: Function;
  blobListDeleted: any;
  setBlobListDeleted: Function;
  reorganizeImages: Function;
  beforeUploadProp: Function;
  uploadImage: Function;
  onChangeProp: Function;
  deleteImage: Function;
  imageList: any[];
  setImageList: Function;
}
const WorkshopGallery: React.FC<IProps> = ({
  workshopData,
  updateWorkshopData,
  blobListDeleted,
  setBlobListDeleted,
  reorganizeImages,
  beforeUploadProp,
  uploadImage,
  onChangeProp,
  deleteImage,
  imageList,
  setImageList,
}) => {
  const isMobile = useIsMobileScreen(992);

  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file: any) => beforeUploadProp(file, "GALLERY"),
    customRequest: ({ onSuccess, onError, file }: any) => {
      uploadImage(onSuccess, onError, file, "Uploaded on Workshop setup");
    },
    onChange(info: any) {
      onChangeProp(info);
    },
  };

  const renderImages = (imageList: Array<WorkshopImage>) => {
    if (!workshopData?.workshopPhotos) return <></>;

    let imgListCopy = [...workshopData?.workshopPhotos];

    // Remove logo from the list
    const imgListSorted = imgListCopy
      .sort((a: any, b: any) => {
        if (a?.description && b?.description)
          return a.description.localeCompare(b.description);
        else return false;
      })
      .slice(1);

    if (imgListSorted.length < 8 && !isMobile) {
      const fill = 8 - imgListSorted.length;
      for (let index = 0; index < fill; index++) {
        imgListSorted.push({ photoUrl: "" });
      }
    }

    return (
      <div className="workshop-form-gallery">
        {isMobile ? (
          <div className="mobile-add-image">
            <CkEmptyImage {...uploadProps} />
          </div>
        ) : undefined}
        <div className="workshop-images-flex">
          {imgListSorted.slice(0, 4).map((item, index) => {
            let imgIndex = imageList.findIndex(
              (originalImage: any) => item.photoUrl === originalImage.photoUrl
            );
            return (
              <div className="logo-container">
                {item.photoUrl && item.photoUrl !== "" ? (
                  <ImgComponent
                    photoUrl={item.photoUrl}
                    imgIndex={imgIndex}
                    reorganizeImages={reorganizeImages}
                    imageList={imageList}
                    deleteImage={deleteImage}
                  />
                ) : (
                  <CkEmptyImage {...uploadProps} />
                )}
              </div>
            );
          })}

          {imgListSorted.length > 4 && (
            <>
              {imgListSorted.slice(4).map((item) => {
                let imgIndex = imageList.findIndex(
                  (originalImage: any) =>
                    item.photoUrl === originalImage.photoUrl
                );
                return (
                  <div className="logo-container ws-image-wrapped">
                    {item.photoUrl && item.photoUrl !== "" ? (
                      <ImgComponent
                        photoUrl={item.photoUrl}
                        imgIndex={imgIndex}
                        reorganizeImages={reorganizeImages}
                        imageList={imageList}
                        deleteImage={deleteImage}
                      />
                    ) : (
                      <CkEmptyImage {...uploadProps} />
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Row>
        <p className="form-section-title" style={{ marginBottom: 0 }}>
          Galería
        </p>
      </Row>
      <p style={{ paddingTop: "10px", fontSize: "16px" }}>
        <span style={{ fontWeight: "600" }}>Agregar imágenes del taller</span>{" "}
        (hasta 8):
      </p>
      {renderImages(imageList)}
    </Fragment>
  );
};

export default memo(WorkshopGallery);
