import React, { useState, useEffect, useCallback, memo } from "react";
import { Row, Col, Form, Input, Select, Tooltip, InputNumber } from "antd";
import TextArea from "antd/lib/input/TextArea";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
import "./styles.css";
import { VehicleBrand } from "shared";
import { CkIcon } from "../../../../CkUI";
import LogoSelection from "../LogoSelection";
import { useIsMobileScreen } from "../../../Utilities";

const { Option } = Select;

interface IProps {
  workshopData: any;
  updateWorkshopData: Function;
  blobListDeleted: any;
  setBlobListDeleted: Function;
  reorganizeImages: Function;
  deleteImage: Function;
  beforeUploadProp: Function;
  onChangeProp: Function;
  uploadImage: Function;
  imageList: any[];
  setImageList: Function;
  isNewWorkshop: boolean;
}

const WorkshopData: React.FC<IProps> = ({
  workshopData,
  updateWorkshopData,
  blobListDeleted,
  setBlobListDeleted,
  reorganizeImages,
  deleteImage,
  beforeUploadProp,
  onChangeProp,
  uploadImage,
  imageList,
  setImageList,
  isNewWorkshop
}) => {
  const isMobile768 = useIsMobileScreen(768);
  const [vehicleTypes, setVehicleTypes] = useState<VehicleBrand[]>([]);

  const wsProfileRefCallback = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let result = await CatalogAPI.fetchVehicleTypes();
      if (result?.status === 200) {
        const mappedResult = result.data;
        setVehicleTypes(mappedResult);
      }
    };
    fetchData();
  }, []);

  return (
    <div ref={wsProfileRefCallback} className="ws-form-data-section">
      <Row>
        <Col sm={24} md={24} style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "17px",
            }}
          >
            <div className="form-section-title">Datos del taller</div>
            <div style={{ fontSize: "14px", color: "#AAAAAA" }}>
              * = Obligatorio
            </div>
          </div>
        </Col>

        <Col sm={24} md={7} xl={6} style={{ width: "100%" }}>
          <div className="ws-form-logo-container">
            <p
              style={{
                textAlign: "start",
                margin: 0,
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              *Logo del taller
            </p>
            <LogoSelection
              images={workshopData?.workshopPhotos}
              updateWorkshopData={updateWorkshopData}
              blobListDeleted={blobListDeleted}
              setBlobListDeleted={setBlobListDeleted}
              reorganizeImages={reorganizeImages}
              deleteImage={deleteImage}
              beforeUploadProp={beforeUploadProp}
              onChangeProp={onChangeProp}
              uploadImage={uploadImage}
              imageList={imageList}
              setImageList={setImageList}
            />
          </div>
        </Col>

        <Col sm={24} md={17} xl={18} style={{ paddingTop: "20px" }}>
          <Row>
            {/*Nombre*/}
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Ingresa el nombre del taller",
                  },
                  {
                    max: 50,
                    message: "No puede excederse de 50 caracteres",
                  },
                ]}
                name="name"
                extra={<span id={isNewWorkshop ? "ACCTSETUP002" : "ACCTSETUP015"}>*Nombre del taller (nombre comercial)</span>}
                style={{ paddingRight: "20px" }}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder="Ingresa el nombre del taller"
                  onChange={(e: any) => {
                    updateWorkshopData({ name: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>

            {/*Tipo de vehiculo*/}
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                name="carTypes"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el tipo del vehículo",
                  },
                ]}
                extra="*Tipo de vehículo (selecciona hasta 3)"
              >
                <Select
                  className="select-vehicle-type"
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="*Tipo de vehículo"
                  getPopupContainer={(triggerNode: any) =>
                    triggerNode || document.body
                  }
                  onChange={(value: any) => {
                    updateWorkshopData({ carTypes: value });
                  }}
                >
                  {vehicleTypes.map((item) => {
                    let itemDescription: string;
                    if (!isMobile768) {
                      itemDescription =
                        item.desc === "VEHICULOS DE CARGA LIGERA"
                          ? "CARGA LIGERA"
                          : item.desc;
                    } else {
                      itemDescription = item.desc;
                    }
                    return (
                      <Option value={item.code} key={item.code}>
                        {`${itemDescription[0]}${itemDescription
                          .toLowerCase()
                          .substring(1)}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {/*ID*/}
            <Col md={12} sm={24} style={{ width: "100%" }}>
              <Form.Item
                name="WorkshopIdentificator"
                extra="ID"
                style={{ paddingRight: "20px" }}
              >
                <InputNumber
                  defaultValue={workshopData.workshopIdentificator}
                  type="number"
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Ingresa un número para identificar al taller: Ej. 01"
                  onChange={(value: any) => {
                    updateWorkshopData({
                      WorkshopIdentificator: value.toString(),
                    });
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: "3px",
                  }}
                >
                  <CkIcon name="information-round" width={18} height={18} />
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#404040",
                      paddingLeft: "3px",
                    }}
                  >
                    Este dato interno, visible solo para los usuarios de tu
                    equipo de CarKer, puede ayudarte en la gestión de múltiples
                    talleres.
                  </p>
                </div>
              </Form.Item>
            </Col>

            {/*Categoria*/}
            <Col md={12} sm={24}>
              <Form.Item name="workshopCategoryId" extra="Categoría">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p
                    className="simple-lightblue-highlight"
                    style={{ marginRight: "10px" }}
                  >
                    Por definir
                  </p>
                  <Tooltip title="La categoría del taller es asignada por CarKer de acuerdo a ciertas características.">
                    <CkIcon name="information-round" />
                  </Tooltip>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      {/*Acerca de*/}
      <Row gutter={[26, 30]} style={{ paddingTop: "15px" }}>
        <Form.Item
          name="about"
          extra="Acerca de"
          className="workshop-form-about"
          style={{
            width: "100%",
            padding: "0px 13px",
          }}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            placeholder="Escribe tu mensaje, máximo 240 caracteres"
            maxLength={240}
            onChange={(e: any) => {
              updateWorkshopData({ about: e.target.value });
            }}
          />
        </Form.Item>
      </Row>
    </div>
  );
};

export default memo(WorkshopData);
