import React, { memo } from "react";
import { Col, Row, Form, Input } from "antd";

interface IProps {
  updateWorkshopData: Function;
}

const ContactData: React.FC<IProps> = ({updateWorkshopData}) => {
  return (
    <Row justify="start">
      <Col span={24}>
        <p className="form-section-title">
          Datos de contacto
        </p>
      </Col>
      <Col sm={24} md={8} style={{ width: "100%", paddingRight: "20px"}}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Ingresa el correo electrónico",
            },
            {
              type: "email",
              message: "Debes ingresar un correo electrónico",
            },
          ]}
          name="email"
          extra={<span id="ACCTSETUP021">*Correo electrónico principal</span>}
        >
          <Input
            name="email"
            placeholder="Escribe el correo electrónico de atención"
            onChange={(e: any)=>{
              updateWorkshopData({ email: e.target.value})
            }}
          />
        </Form.Item>
      </Col>

      <Col sm={24} md={8} style={{ width: "100%", paddingRight: "20px"}}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Ingresa  el número de teléfono principal",
            },
            {
              pattern: new RegExp(/^[0-9]*$/gi),
              message: "Sólo se permiten números",
            },
            {
              max: 10,
              min: 10,
              message: "El teléfono debe ser de 10 números",
            },
          ]}
          name="workshopPhoneNumber"
          extra={<span id="ACCTSETUP023">*Teléfono principal</span>}
        >
          <Input
            name="workshopPhoneNumber"
            placeholder="Escribe el teléfono de atención"
            onChange={(e: any)=>{
              updateWorkshopData({ workshopPhoneNumber: e.target.value})
            }}
          />
        </Form.Item>
      </Col>

      <Col sm={24} md={8} style={{ width: "100%", paddingRight: "20px"}}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Ingresa el teléfono celular",
            },
            {
              pattern: new RegExp(/^[0-9]*$/gi),
              message: "Sólo se permiten números",
            },
            {
              max: 10,
              min: 10,
              message: "El teléfono debe ser de 10 números",
            },
          ]}
          name="customerPhoneNumber"
          extra={<span id="ACCTSETUP024">*Teléfono celular</span>}
        >
          <Input
            name="customerPhoneNumber"
            placeholder="Escribe el celular de atención"
            onChange={(e: any)=>{
              updateWorkshopData({ customerPhoneNumber: e.target.value})
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default memo(ContactData);
