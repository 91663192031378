import React, { Fragment, memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDays, getSasToken } from "../../../../stores";
import {
  Button,
  Col,
  Row,
  Form,
  Radio,
  Divider,
  Switch,
  TimePicker,
} from "antd";
import { Day, fetchDays, WorkHours, Workshop } from "shared";
import moment from "moment/moment";
import { gtmSetId, useWindowSize } from "../../../Utilities";
import { CkMessage } from "../../../../CkUI";

let defaultWorkingHours: WorkHours[] = [
  {
    dayCode: 1,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 2,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 3,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 4,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 5,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 6,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 7,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
];
interface IProps {
  updateWorkshopData: Function;
  workshopData: any;
  reset: boolean;
  setReset: Function;
}

const ScheduleSelection: React.FC<IProps> = ({
  updateWorkshopData,
  workshopData,
  reset,
  setReset,
}) => {
  const windowSize = useWindowSize();
  const format = "HH:mmZ";
  const [useSameHour, setUseSameHour] = useState(false);
  const [useSameHourMondayToFriday, setUseSameHourMondayToFriday] =
    useState(false);
  const days: Day[] = useSelector(getDays);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDays("ES-MX"));
    setUseSameHour(false);
    setUseSameHourMondayToFriday(false);
  }, []);

  useEffect(() => {
    if (reset) {
      updateWorkshopData({ workingHours: defaultWorkingHours });
      setUseSameHour(false);
      setUseSameHourMondayToFriday(false);
      setReset(false);
    }
  }, [reset]);

  useEffect(() => {
    if (workshopData?.workingHours.length === 0) {
      updateWorkshopData({ workingHours: defaultWorkingHours });
    }
  }, [workshopData]);

  useEffect(() => {
    if (workshopData?.workingHours.length !== 7)
      updateWorkshopData({
        workingHours: days.map((day) => {
          return {
            dayCode: day.dayCode,
            openTime: "",
            closeTime: "",
            isOpen: false,
          };
        }),
      });
    /*
      setSchedule(
        days.map((day) => {
          return {
            dayCode: day.dayCode,
            openTime: "",
            closeTime: "",
            isOpen: false,
          };
        })
      );*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateWorkshopData({ UseSameTimeEveryDay: useSameHour });
  }, [useSameHour]);

  useEffect(() => {
    updateWorkshopData({
      useSameHourMondayToFriday: useSameHourMondayToFriday,
    });
  }, [useSameHourMondayToFriday]);

  const getWorkingDayByDayId = (dayCode: number): WorkHours => {
    let workingDay = workshopData?.workingHours.find((workingDay: any) => {
      return workingDay.dayCode === dayCode;
    });

    return workingDay
      ? workingDay
      : { dayCode: -1, openTime: "", closeTime: "", isOpen: false };
  };

  const handlerWorkingDayHourChange = useCallback(
    (time: moment.Moment, index: number, name: string) => {
      let timeString = time.toDate().toISOString();
      timeString = timeString.substring(timeString.indexOf("T") + 1);
      /*setWorkshopSettings((_workshopSettings) => {
        let scheduleDays = [..._workshopSettings.workingHours];
        scheduleDays[index] = { ...scheduleDays[index], [name]: timeString };
        return { ..._workshopSettings, workingHours: scheduleDays };
      });*/
      let scheduleDays = [...workshopData.workingHours];
      scheduleDays[index] = { ...scheduleDays[index], [name]: timeString };
      updateWorkshopData({ workingHours: scheduleDays });
    },
    [workshopData]
  );

  const toggleDays = useCallback(
    (index: number, checked: boolean) => {
      let scheduleDays = [...workshopData?.workingHours];
      if (index < scheduleDays.length) {
      }
      scheduleDays[index] = {
        ...scheduleDays[index],
        isOpen: checked,
        openTime: checked ? scheduleDays[index].openTime : "",
        closeTime: checked ? scheduleDays[index].closeTime : "",
      };
      updateWorkshopData({ workingHours: scheduleDays });
      sessionStorage.setItem("talleres_formchanged", "true"); // setIsTouched
    },
    [workshopData]
  );

  const verifyClosingHour = (time: moment.Moment | null, index: number) => {
    //let workingFormHours = form.getFieldValue("workingHours");
    let workingFormHours = workshopData?.workingHours;

    let init = moment(workingFormHours[index].openTime, "HH:mmZ");
    if (time?.isBefore(init, "day")) {
      time.add(1, "day");
    } else if (time?.isAfter(init, "day")) {
      time.subtract(1, "day");
    }

    if (init >= time!) {
      CkMessage({
        type: "error",
        text: "El horario de cierre debe ser mayor al horario de apertura."
      });

      return;
    }
    handlerWorkingDayHourChange(time, index, "closeTime");
  };

  const verifyOpeningHour = (time: moment.Moment | null, index: number) => {
    //let workingFormHours = form.getFieldValue("workingHours");
    let workingFormHours = workshopData?.workingHours;

    let closingHour = moment(workingFormHours[index].closeTime, "HH:mmZ");
    if (time?.isBefore(closingHour, "day")) {
      time.add(1, "day");
    } else if (time?.isAfter(closingHour, "day")) {
      time.subtract(1, "day");
    }

    if (closingHour < time!) {
      CkMessage({
        type: "error",
        text: "El horario apertura debe ser menor al horario de cierre."
      });
      return;
    }
    handlerWorkingDayHourChange(time, index, "openTime");
  };

  return (
    <Fragment>
      <p className="form-section-title">Horario de trabajo</p>
      <p style={{ fontSize: "16px", fontWeight: "600" }}>
        *Ingresa los horarios de trabajo de este taller
      </p>
      <div className="working-hours-container">
        <Row id="workshop-schedule-form" gutter={[24, 8]}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
            }}
          >
            <Form.Item name="UseSameTimeEveryDay">
              <Radio
                className="--custom"
                checked={useSameHour}
                id="ACCTSETUP030"
                onClick={(ev) => {
                  gtmSetId(ev.currentTarget);
                  setUseSameHour((_sameHour) => {
                    if (!_sameHour) {
                      updateWorkshopData({
                        UseSameHourMondayToFriday: false,
                        UseSameTimeEveryDay: true,
                      });
                      setUseSameHourMondayToFriday(false);
                      return true;
                    } else {
                      updateWorkshopData({
                        UseSameTimeEveryDay: false,
                      });
                      return false;
                    }
                  });
                }}
              >
                Usar el mismo horario para todos los días
              </Radio>
            </Form.Item>
            <Form.Item name="UseSameTimeMondayToFriday">
              <Radio
                className="--custom"
                id="ACCTSETUP031"
                checked={useSameHourMondayToFriday}
                onClick={(ev) => {
                  gtmSetId(ev.currentTarget);
                  setUseSameHourMondayToFriday((_sameHour) => {
                    if (!_sameHour) {
                      updateWorkshopData({
                        UseSameTimeEveryDay: false,
                        UseSameHourMondayToFriday: true,
                      });
                      setUseSameHour(false);
                      return true;
                    } else {
                      updateWorkshopData({
                        UseSameHourMondayToFriday: false,
                      });
                      return false;
                    }
                  });
                }}
              >
                Usar el mismo horario para lunes a viernes
              </Radio>
            </Form.Item>
          </div>

          <Col xs={24}>
            <Form.Item
              name="workingHours"
              hidden={useSameHour || useSameHourMondayToFriday}
            >
              {days.map((day, index) => (
                <div key={day.dayCode}>
                  <Divider style={{ margin: "10px 0" }} />
                  <Row gutter={[16, 8]} style={{ alignItems: "center" }}>
                    <Col
                      sm={2}
                      xs={12}
                      flex="auto"
                      order={windowSize.width >= 576 ? 1 : 2}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Switch
                          checked={getWorkingDayByDayId(day.dayCode).isOpen}
                          onClick={(checked) => {
                            toggleDays(index, checked);
                          }}
                        />
                        <p
                          style={{
                            margin: "0",
                            fontFamily: "EMprint-regular",
                            fontSize: "16px",
                          }}
                        >
                          {day.dayName}
                        </p>
                      </div>
                    </Col>
                    <Col sm={16} xs={24} order={3}>
                      <Row gutter={[16, 16]}>
                        {windowSize.width >= 576 && <Col flex="auto"></Col>}
                        <Col sm={8} xs={12}>
                          <TimePicker
                            inputReadOnly
                            minuteStep={30}
                            showNow={false}
                            style={{ width: "100%" }}
                            disabled={!getWorkingDayByDayId(day.dayCode).isOpen}
                            placeholder="Inicio:"
                            value={
                              getWorkingDayByDayId(day.dayCode).openTime === ""
                                ? null
                                : moment(
                                    getWorkingDayByDayId(day.dayCode).openTime,
                                    format
                                  )
                            }
                            format={"HH:mm"}
                            onChange={(time: any) =>
                              verifyOpeningHour(time, index)
                            }
                            // popupStyle={{ width: "100%" }}
                            // className={window.screen.width > 992 ? "" : "mobile-hour"}
                          />
                        </Col>
                        <Col sm={8} xs={12}>
                          <TimePicker
                            inputReadOnly
                            minuteStep={30}
                            showNow={false}
                            style={{ width: "100%" }}
                            placeholder="Fin:"
                            disabled={!getWorkingDayByDayId(day.dayCode).isOpen}
                            value={
                              getWorkingDayByDayId(day.dayCode).closeTime === ""
                                ? null
                                : moment(
                                    getWorkingDayByDayId(day.dayCode).closeTime,
                                    format
                                  )
                            }
                            format={"HH:mm"}
                            onChange={(time: any) =>
                              verifyClosingHour(time, index)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ))}
            </Form.Item>

            <Form.Item name="everyDayHours" hidden={!useSameHour}>
              <Divider style={{ margin: 0 }} />
              <Row
                gutter={[16, 8]}
                style={{ alignItems: "center", marginTop: 10 }}
              >
                <Col md={14} xs={24}>
                  <p
                    style={{
                      margin: "0",
                      fontFamily: "EMprint-regular",
                      fontSize: "16px",
                    }}
                  >
                    Todos los días
                  </p>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["everyDayHours", "startingTime"]}
                    rules={[
                      () => ({
                        validator(_: any, value: any) {
                          if ((value && useSameHour) || !useSameHour) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Este campo es obligatorio");
                          }
                        },
                      }),
                      () => ({
                        validator(_: any, value: any) {
                          if (!useSameHour) {
                            return Promise.resolve();
                          }
                          let closingTime =
                            workshopData?.everyDayHours?.endingTime;
                          if (closingTime) {
                            if (value >= closingTime) {
                              return Promise.reject(
                                "La hora de apertura debe ser menor a la de cierre"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      inputReadOnly
                      minuteStep={30}
                      showNow={false}
                      style={{ width: "90px" }}
                      placeholder="Inicio:"
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["everyDayHours", "endingTime"]}
                    rules={[
                      () => ({
                        validator(_: any, value: any) {
                          if ((value && useSameHour) || !useSameHour) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Este campo es obligatorio");
                          }
                        },
                      }),
                      () => ({
                        validator(_: any, value: any) {
                          if (!useSameHour) {
                            return Promise.resolve();
                          }
                          let startingTime =
                            workshopData?.everyDayHours?.startingTime;
                          if (startingTime) {
                            if (value <= startingTime) {
                              return Promise.reject(
                                "La hora de cierre debe ser mayor a la de apertura"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      inputReadOnly
                      minuteStep={30}
                      showNow={false}
                      style={{ width: "90px" }}
                      placeholder="Fin:"
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              name="mondayToFridayDayHours"
              hidden={!useSameHourMondayToFriday}
            >
              <Divider style={{ margin: 0 }} />
              <Row
                gutter={[16, 8]}
                style={{
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Col md={16} xs={24}>
                  <p
                    style={{
                      margin: "0",
                      fontFamily: "EMprint-regular",
                      fontSize: "16px",
                    }}
                  >
                    Lunes a viernes
                  </p>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["mondayToFridayDayHours", "startingTime"]}
                    rules={[
                      () => ({
                        validator(_: any, value: any) {
                          if (
                            (value && useSameHourMondayToFriday) ||
                            !useSameHourMondayToFriday
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Este campo es obligatorio");
                          }
                        },
                      }),
                      () => ({
                        validator(_: any, value: any) {
                          if (!useSameHourMondayToFriday) {
                            return Promise.resolve();
                          }
                          let closingTime =
                            workshopData?.mondayToFridayDayHours?.endingTime;
                          if (closingTime) {
                            if (value >= closingTime) {
                              return Promise.reject(
                                "La hora de apertura debe ser menor a la de cierre"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      inputReadOnly
                      minuteStep={30}
                      showNow={false}
                      style={{ width: "100%" }}
                      placeholder="Inicio:"
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["mondayToFridayDayHours", "endingTime"]}
                    rules={[
                      () => ({
                        validator(_: any, value: any) {
                          if (
                            (value && useSameHourMondayToFriday) ||
                            !useSameHourMondayToFriday
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Este campo es obligatorio");
                          }
                        },
                      }),
                      () => ({
                        validator(_: any, value: any) {
                          if (!useSameHourMondayToFriday) {
                            return Promise.resolve();
                          }
                          let startingTime =
                            workshopData?.mondayToFridayDayHours?.startingTime;
                          if (startingTime) {
                            if (value <= startingTime) {
                              return Promise.reject(
                                "La hora de cierre debe ser mayor a la de apertura"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      inputReadOnly
                      minuteStep={30}
                      showNow={false}
                      style={{ width: "100%" }}
                      placeholder="Fin:"
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="workingHourssaturdaysunday"
                style={{ marginBottom: 0 }}
              >
                {days.map(
                  (day, index) =>
                    (day.dayCode === 6 || day.dayCode === 7) && (
                      <div key={day.dayCode}>
                        <Divider style={{ margin: 0 }} />
                        <Row
                          gutter={[16, 8]}
                          style={{
                            alignItems: "center",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <Col md={16} xs={24} flex="auto">
                            <div style={{ display: "flex", gap: "10px" }}>
                              <Switch
                                checked={
                                  getWorkingDayByDayId(day.dayCode).isOpen
                                }
                                onClick={(checked) => {
                                  toggleDays(index, checked);
                                }}
                              />
                              <p
                                style={{
                                  margin: "0",
                                  fontFamily: "EMprint-regular",
                                  fontSize: "16px",
                                }}
                              >
                                {day.dayName}
                              </p>
                            </div>
                          </Col>
                          <Col md={4} xs={12}>
                            <TimePicker
                              inputReadOnly
                              minuteStep={30}
                              showNow={false}
                              style={{ width: "100%" }}
                              disabled={
                                !getWorkingDayByDayId(day.dayCode).isOpen
                              }
                              placeholder="Inicio:"
                              value={
                                getWorkingDayByDayId(day.dayCode).openTime ===
                                ""
                                  ? null
                                  : moment(
                                      getWorkingDayByDayId(day.dayCode)
                                        .openTime,
                                      format
                                    )
                              }
                              format={"HH:mm"}
                              onChange={(time: any) =>
                                verifyOpeningHour(time, index)
                              }
                            />
                          </Col>
                          <Col md={4} xs={12}>
                            <TimePicker
                              inputReadOnly
                              minuteStep={30}
                              showNow={false}
                              style={{ width: "100%" }}
                              placeholder="Fin:"
                              disabled={
                                !getWorkingDayByDayId(day.dayCode).isOpen
                              }
                              value={
                                getWorkingDayByDayId(day.dayCode).closeTime ===
                                ""
                                  ? null
                                  : moment(
                                      getWorkingDayByDayId(day.dayCode)
                                        .closeTime,
                                      format
                                    )
                              }
                              format={"HH:mm"}
                              onChange={(time: any) =>
                                verifyClosingHour(time, index)
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    )
                )}
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default memo(ScheduleSelection);
