import React, { Fragment, memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setLocation } from "../../../../stores";
import { Col, Form, Input, Row } from "antd";
import { CatalogAPI } from "../../../Services/axios";
import {
  gtmAddLayer,
  useIsMobileScreen,
  useWindowSize,
} from "../../../Utilities";
import { CkMessage } from "../../../../CkUI";

import "./styles.css";

interface IProps {
  updateWorkshopData: Function;
  location: any;
}

declare var google: any;
let map: any = null;
let marker: any = null;
let autoComplete: any = null;
let geocoder: any = null;

const LocationSelection: React.FC<IProps> = ({
  updateWorkshopData,
  location,
}) => {
  const isMobileScreen = useIsMobileScreen(992);
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const [loadingMap, setLoadingMap] = useState<boolean>(true);
  let autoCompleteRef = useRef({ value: "" } as HTMLInputElement);

  useEffect(() => {
    getPosition();
  }, [isMobileScreen]);

  {
    /*
  useEffect(() => {
    if (!canEdit && map) {
      google.maps.event.clearListeners(map, "click");
      marker.setOptions({ draggable: false });
    }
  }, [map]);
  */
  }

  useEffect(() => {
    if (location && map && !loadingMap) {
      let coordinates = {
        lat:
          location.latitude === 0 || location.latitude === ""
            ? 19.42847
            : location.latitude,
        lng:
          location.longitude === 0 || location.longitude === ""
            ? -99.12766
            : location.longitude,
      };
      marker.setVisible(false);
      map.setCenter(coordinates);
      map.setZoom(17);
      marker.setPosition(coordinates);
      marker.setVisible(true);
    }
  }, [location, map, loadingMap]);

  const geoCoding = (event: any) => {
    const latlng = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    geocoder.geocode(
      {
        location: latlng,
      },
      (results: any, status: any) => {
        if (status === "OK") {
          if (results[0]) {
            fillAdress(
              results[0].address_components,
              event.latLng,
              results[0].formatted_address
            );
          } else {
            CkMessage({ type: "error", text: "No se encontraron resultados."});
          }
        } else {
          CkMessage({ type: "error", text: "Error al realizar la solicitud."});
        }
      }
    );
  };

  const setMap = (center: any) => {
    const currentMap = document.getElementById("map");

    geocoder = new google.maps.Geocoder();

    map = new google.maps.Map(currentMap, {
      center: center,
      zoom: 16,
      disableDefaultUI: false,
    });

    marker = new google.maps.Marker({
      position: center,
      draggable: true,
      map: map
    });

    google.maps.event.addListener(map, "click", function (event: any) {
      geoCoding(event);
      sessionStorage.setItem("talleres_formchanged", "true"); // setIsTouched
      gtmAddLayer({
        event: "map_click",
      });
    });

    google.maps.event.addListener(marker, "dragend", function (event: any) {
      geoCoding(event);
      sessionStorage.setItem("talleres_formchanged", "true"); // setIsTouched
      gtmAddLayer({
        event: "map_pin_dragend",
      });
    });

    autoComplete = new google.maps.places.Autocomplete(
      autoCompleteRef.current
    ) as any;
    autoComplete.bindTo("bounds", map);
    autoComplete.setFields(["address_components", "geometry"]);
    autoComplete.addListener("place_changed", handlePlaceSelect);
    setLoadingMap(false);
  };

  const getPosition = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMap({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (err) => {
          setMap({
            lat: 19.42847,
            lng: -99.12766,
          });
        }
      );
    } else {
      setMap({
        lat: 19.42847,
        lng: -99.12766,
      });
    }
  };

  const fillAdress = (
    address: Array<any>,
    coordinates: any,
    addressMarker: string = ""
  ) => {
    CkMessage({ type: "loading", text: "Cargando dirección", duration: 15});
    let localAddress = {
      address: "",
      zipCode: "",
      latitude: "",
      longitude: "",
      city: "",
      municipality: "",
      neighborhood: "",
      WorkshopStateProvince: "",
      country: "",
    };

    let componentForm = {
      street_number: "short_name",
      route: "long_name",
      sublocality_level_1: "short_name",
      sublocality: "short_name",
      locality: "long_name",
      administrative_area_level_1: "long_name",
      country: "long_name",
      postal_code: "short_name",
    };

    for (const component of address) {
      const addressType = component.types[0] as
        | "street_number"
        | "route"
        | "sublocality_level_1"
        | "sublocality"
        | "locality"
        | "administrative_area_level_1"
        | "country"
        | "postal_code";

      if (componentForm[addressType]) {
        const val = component[componentForm[addressType]];
        switch (addressType) {
          case "postal_code":
            localAddress.zipCode = val;
            break;
          case "sublocality_level_1":
          case "sublocality":
            localAddress.neighborhood =
              localAddress.neighborhood.trim() === ""
                ? val
                : localAddress.neighborhood;
            break;
          case "locality":
            localAddress.municipality = val;
            break;
          case "administrative_area_level_1":
            localAddress.city = val;
            localAddress.WorkshopStateProvince = val;
            break;
          case "country":
            localAddress.country = val;
            break;

          default:
            break;
        }
      }
    }
    if (
      localAddress.country === "Mexico" ||
      localAddress.country === "México"
    ) {
      localAddress.address =
        addressMarker === "" ? autoCompleteRef.current.value : addressMarker;
      localAddress.latitude = coordinates.lat();
      localAddress.longitude = coordinates.lng();

      CatalogAPI.getLocationInformation(coordinates.lat(), coordinates.lng())
        .then((resp) => {
          const data = resp.data.response;
          localAddress.address = data.label ? data.label : localAddress.address;
          localAddress.zipCode = data.cp ? data.cp : localAddress.zipCode;
          localAddress.country = data.pais ? data.pais : localAddress.country;
          localAddress.city = data.estado ? data.estado : localAddress.city;
          localAddress.municipality = data.municipio
            ? data.municipio
            : localAddress.municipality;
          localAddress.WorkshopStateProvince = data.estado
            ? data.estado
            : localAddress.WorkshopStateProvince;
          localAddress.neighborhood = data.asentamiento
            ? data.asentamiento
            : localAddress.neighborhood
            ? localAddress.neighborhood
            : data.estado
            ? data.estado
            : localAddress.WorkshopStateProvince;
          dispatch(setLocation(localAddress))
        })
        .catch((err) => {
          console.info("error", err);
          CkMessage({ type: "error", text: "Error al realizar la solicitud."});
          dispatch(setLocation({
            address: "",
            zipCode: "",
            latitude: "",
            longitude: "",
            city: "",
            municipality: "",
            neighborhood: "",
            WorkshopStateProvince: "",
            country: "",
          }));
        });

      marker.setVisible(false);
      map.setCenter(coordinates);
      map.setZoom(17);
      marker.setPosition(coordinates);
      marker.setVisible(true);
    } else {
      CkMessage({ type: "error", text: "La ubicación no se encuentra en Mexico."});
      dispatch(setLocation({
        address: "",
        zipCode: "",
        latitude: "",
        longitude: "",
        city: "",
        municipality: "",
        neighborhood: "",
        WorkshopStateProvince: "",
        country: "",
      }));
    }
  };

  const handlePlaceSelect = async () => {
    let place = autoComplete.getPlace();
    try {
      if (!place.geometry) {
        alert("No details available for input: '" + place.name + "'");
        return;
      }
      fillAdress(place.address_components, place.geometry.location);
    } catch {}
  };

  return (
    <Fragment>
      <p className="form-section-title">Ubicación</p>
      <Row>
        {/*Form section*/}
        <Col sm={24} md={12} style={{ width: "100%"}} >
          <Form.Item name={["location", "latitude"]} hidden={true} />
          <Form.Item name={["location", "longitude"]} hidden={true} />
          <Form.Item name={["location", "WorkshopStateProvince"]} hidden={true} />
          <Row>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Ingresa código postal",
                  },
                  {
                    max: 5,
                    min: 5,
                    message: "El Código postal debe ser de 5 caracteres",
                  },
                  {
                    pattern: new RegExp(/^[0-9]*$/gi),
                    message: "Sólo se permiten números.",
                  },
                ]}
                name={["location", "zipCode"]}
                extra="*Código postal"
                style={{ paddingRight: "20px"}}
              >
                <Input
                  placeholder="Escribe el código postal"
                  disabled
                  onClick={() => {
                    gtmAddLayer({
                      event: "map_postal_code_input",
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Ingresa la Colonia",
                  },
                ]}
                name={["location", "neighborhood"]}
                extra="*Colonia"
              >
                <Input
                  placeholder="Selecciona colonia"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Ingresa el Municipio o Alcaldía",
                  },
                ]}
                name={["location", "municipality"]}
                extra="*Municipio o Alcaldía"
                style={{ paddingRight: "20px"}}
              >
                <Input
                  placeholder="Selecciona Municipio o Alcaldía"
                  disabled
                  onClick={() => {
                    gtmAddLayer({
                      event: "map_municipality_input",
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Ingresa el Estado",
                  },
                ]}
                name={["location", "city"]}
                extra="*Estado"
              >
                <Input
                  placeholder="Seleccionar Estado"
                  disabled
                  onClick={() => {
                    gtmAddLayer({
                      event: "map_city_input",
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Ingresa la calle y el número",
                  },
                ]}
                name={["location", "address"]}
                extra="*Dirección"
              >
                <input
                  className="ant-input"
                  placeholder="Completa la dirección"
                  disabled
                  onClick={() => {
                    gtmAddLayer({
                      event: "map_address_input",
                    });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        {/*Map section*/}
        <Col className="location-container" md={12} sm={24} style={{ width: "100%"}}>
          <div id="workshop-location-form4" className="adjust-marker-container">
            <div>Mueve el marcador para ajustar la ubicación</div>
          </div>
          <div id="map" className="map-container"></div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default memo(LocationSelection);
