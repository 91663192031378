import React, { Fragment, memo } from "react";
import { Row, Form } from "antd";
import { CkButton } from "../../../../CkUI";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetData } from "../../../../stores";
import { useIsMobileScreen } from "../../../Utilities";

interface IProps {
  isNewWorkshop: boolean;
  redirectTo?: string;
}

const ActionButtons: React.FC<IProps> = ({ isNewWorkshop, redirectTo }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobileScreen(992);
  return (
    <Row justify="end">
      <Form.Item>
        {isMobile ? (
          <Row justify={"center"}>
            <CkButton 
              variant="primary" 
              htmlType="submit" 
              triggerGTMOnClick
              id={isNewWorkshop ? "ACCTSETUP006" : "ACCTSETUP014"}
            >
              Guardar datos
            </CkButton>
            <CkButton
              variant="link"
              onClick={() => {
                if (redirectTo) {
                  history.push(redirectTo);
                } else {
                  history.push("/");
                }
                dispatch(resetData());
              }}
              triggerGTMOnClick
              id={isNewWorkshop ? "ACCTSETUP005" : "ACCTSETUP013"}
            >
              Cancelar
            </CkButton>
          </Row>
        ) : (
          <Fragment>
            <CkButton
              variant="link"
              onClick={() => {
                if (redirectTo) {
                  history.push(redirectTo);
                } else {
                  history.push("/");
                }
                dispatch(resetData());
              }}
              triggerGTMOnClick
              id={isNewWorkshop ? "ACCTSETUP005" : "ACCTSETUP013"}
            >
              Cancelar
            </CkButton>
            <CkButton 
              variant="primary" 
              htmlType="submit" 
              triggerGTMOnClick
              id={isNewWorkshop ? "ACCTSETUP006" : "ACCTSETUP014"}
            >
              Guardar datos
            </CkButton>
          </Fragment>
        )}
      </Form.Item>
    </Row>
  );
};

export default memo(ActionButtons);
